import React from "react";

import Result404 from "../common/result/404"
import Home from "../pages/home/index";

// 路由表
export const routes = [
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: "*",
        element: <Result404/>,
    }
];
