import React, {useEffect, useState} from "react";

function Home() {
    
    const [show, setShow] = useState(true);
    const [lastY, setLastY] = useState(0);
    useEffect(() => {
        window.addEventListener("scroll", controlNavbar);
        return () => {
            window.removeEventListener("scroll", controlNavbar);
        };
    }, [lastY])
    const controlNavbar = () => {
        setShow(window.scrollY < lastY)
        setLastY(window.scrollY);
    };
    
    const scrollTo = (id) => {
        // 使用ID来获取对特定div的引用
        const thirdBlock = document.getElementById(id);
        if (thirdBlock) {
            const topPos = thirdBlock.offsetTop;
            window.scrollTo({
                top: topPos - 110, // 目标位置加上额外距离300像素
                behavior: "smooth"
            });
        }
    }
    
    return (
        <>
            <div style={{display: "flex", flexDirection: "column", gap: 0, marginBottom: "100vh"}}>
                <div style={{color: "#265825", position: "sticky", top: 0, backgroundColor: "#fff", borderBottom: "1px solid #265825"}}>
                    <div style={{backgroundColor: "#265825", height: 5}}></div>
                    <div style={{
                        backgroundColor: "#fff",
                        position: "relative",
                        zIndex: 1000,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 5
                    }}>
                        <img src="https://leanon.store/logo.png" style={{height: 40, paddingLeft: 10}} alt=""/>
                        {window.innerWidth > 500 ? <div style={{color: "#265825", fontSize: 20}}>LeanOn</div> : null}
                    </div>
                    <div style={{
                        position: "absolute",
                        width: "100%",
                        backgroundColor: "#fff",
                        paddingBottom: 10,
                        display: window.innerWidth > 500 ? "flex" : "none",
                        justifyContent: "center",
                        gap: "0 40px",
                        textAlign: "center",
                        flexWrap: "wrap",
                        marginTop: show ? 0 : -40,
                        transition: "0.3s",
                        borderBottom: "1px solid #265825"
                    }}>
                        <div className="link-button" style={{flex: 1, minWidth: 70, maxWidth: 100, color: "#265825", textDecoration: "none"}} onClick={() => scrollTo("part4")}>鲜活植物系列</div>
                        <div className="link-button" style={{flex: 1, minWidth: 70, maxWidth: 100, color: "#265825", textDecoration: "none"}} onClick={() => scrollTo("part5")}>神秘深海系列</div>
                        <div className="link-button" style={{flex: 1, minWidth: 70, maxWidth: 100, color: "#265825", textDecoration: "none"}} onClick={() => scrollTo("part6")}>地下宝藏系列</div>
                        <div className="link-button" style={{flex: 1, minWidth: 70, maxWidth: 100, color: "#265825", textDecoration: "none"}} onClick={() => scrollTo("part7")}>安瓶面膜系列</div>
                        {/*<div style={{flex: 1, minWidth: 100, maxWidth: 100}}>关于我们</div>*/}
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", overflow: "hidden"}}>
                    <img id="part1" style={{width: "100%", minWidth: 500}}
                         src="https://10kv.oss-cn-shanghai.aliyuncs.com/leanon/poster/v3/1.jpg"
                         alt=""/>
                    {/*<img id="part2" style={{width: "100%", minWidth: 500}}*/}
                    {/*     src="https://10kv.oss-cn-shanghai.aliyuncs.com/leanon/poster/v3/2.jpg"*/}
                    {/*     alt=""/>*/}
                    <div style={{backgroundColor: "#fff", width: "100%", display: "flex", justifyContent: "center"}}>
                        <video src="https://10kv.oss-cn-shanghai.aliyuncs.com/leanon/poster/v3/2.mp4" autoPlay muted style={{width: "70%"}}/>
                    </div>
                    <img id="part3" style={{width: "100%", minWidth: 500}}
                         src="https://10kv.oss-cn-shanghai.aliyuncs.com/leanon/poster/v3/3.jpg"
                         alt=""/>
                    <img id="part4" style={{width: "100%", minWidth: 500}}
                         src="https://10kv.oss-cn-shanghai.aliyuncs.com/leanon/poster/v3/4.jpg"
                         alt=""/>
                    <img id="part5" style={{width: "100%", minWidth: 500}}
                         src="https://10kv.oss-cn-shanghai.aliyuncs.com/leanon/poster/v3/5.jpg"
                         alt=""/>
                    <img id="part6" style={{width: "100%", minWidth: 500}}
                         src="https://10kv.oss-cn-shanghai.aliyuncs.com/leanon/poster/v3/6.jpg"
                         alt=""/>
                    <img id="part7" style={{width: "100%", minWidth: 500}}
                         src="https://10kv.oss-cn-shanghai.aliyuncs.com/leanon/poster/v3/7.jpg"
                         alt=""/>
                </div>
            </div>
            
            <div style={{
                zIndex: -1,
                position: "fixed",
                top: 0,
                height: "100vh",
                width: "100vw",
                backgroundColor: "#265825",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: "#fff"
            }}>
                
                <div style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <img src="https://10kv.oss-cn-shanghai.aliyuncs.com/leanon/logo/logo.jpg" alt=""
                         style={{height: 80, paddingLeft: 20}}/>
                    <div style={{fontSize: 30}}>LeanOn</div>
                </div>
                <div style={{display: "flex", width: "80vw"}}>
                    {
                        window.innerWidth > 250 ?
                            <div style={{flex: 1, color: "#ccc"}}>
                                <div style={{fontWeight: 600, color: "#fff"}}>LeanOn产品</div>
                                <div className="link-button" onClick={() => scrollTo("part4")}>鲜活植物系列</div>
                                <div className="link-button" onClick={() => scrollTo("part5")}>神秘深海系列</div>
                                <div className="link-button" onClick={() => scrollTo("part6")}>地下宝藏系列</div>
                                <div className="link-button" onClick={() => scrollTo("part7")}>安瓶面膜系列</div>
                                {/*<div style={{color: "#999", marginTop: 20}}>更多系列敬请期待</div>*/}
                            </div> : null
                    }
                    {
                        window.innerWidth > 500 ?
                            <div style={{flex: 1, color: "#ccc"}}>
                                <div style={{fontWeight: 600, color: "#fff"}}>线上服务</div>
                                <div className="link-button" onClick={() => window.open("https://www.sephora.cn/brand/story-leanon-494022/")}>Sephora商城</div>
                                <div className="link-button">LeanOn小程序</div>
                            </div> : null
                    }
                    <div style={{flex: 1}}>
                        <div style={{fontWeight: 600}}>关于两两</div>
                        <div style={{color: "#ccc", maxWidth: 200}}>关注两两公众号，获取更多产品信息，诸多活动等你参与
                        </div>
                        <div style={{padding: "5px 0", display: "flex", gap: 10}}>
                            <img src="https://10kv.oss-cn-shanghai.aliyuncs.com/leanon/logo/qrcode.jpg" alt="" style={{width: 80}}/>
                            <div style={{width: 70, height: 70, padding: 5, backgroundColor: "#fff"}}>
                                <img src="https://10kv.oss-cn-shanghai.aliyuncs.com/leanon/logo/sephora-qrcode.png" style={{width: "100%"}} alt=""/>
                            </div>
                        </div>
                        <div style={{fontWeight: 600}}>售后服务</div>
                        <div style={{color: "#ccc"}}>021-61423968</div>
                    </div>
                </div>
                <div style={{
                    padding: "20px 0 40px",
                    borderTop: "1px solid #999",
                    width: "80vw",
                    marginTop: 10,
                }}>
                    <div style={{display: window.innerWidth > 600 ? "flex" : "none", gap: "0 40px",marginBottom: 10}}>
                        <div className="link-button">法律声明及隐私权政策</div>
                        <div className="link-button">Cookies政策</div>
                        <div className="link-button">安全举报</div>
                        <div className="link-button">联系我们</div>
                    </div>
                    <div style={{color: "#aaa", flexWrap: "wrap", display: "flex", gap: "0 40px",}}>
                        <div>Copyright © 2016-2023 LeanOn.Co</div>
                        <div className="link-button" style={{textDecoration: "underline"}} onClick={() => window.open("https://beian.miit.gov.cn/")}>沪ICP备 2023020878号-3</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;
